import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RoomContext } from "../House/House";
import "./Header.css";
import logo from "./logo.svg";
import {InfoContext} from "../Container/Container";

function Header({ house, home }) {
  const { info } = useContext(InfoContext);
  const { room, setRoom } = useContext(RoomContext);
  const navigate = useNavigate();
  const { site, houseId, homeId, orderId, summary } = useParams();
  const roomsRef = useRef(null);
  const [dropdownRooms, setDropdownRooms] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const viewSummary = (_) => {
    setShowDropdown(false);
    navigate(`/${site}/house/${houseId}/${homeId}/${orderId}/summary`);
  };

  const handleRoomChange = (room) => {
    navigate(`/${site}/house/${houseId}/${homeId}/${orderId}`);
    setRoom(room);
    setShowDropdown(false);
  };

  const getActiveRoomName = () => {
    if (summary) {
      return "Kokkuvõte";
    }

    return room.room_name;
  };

  let roomsClassName = "rooms";

  if (dropdownRooms) {
    roomsClassName += " as-dropdown";
  }

  if (showDropdown) {
    roomsClassName += " show";
  }

  useEffect(() => {
    if (roomsRef.current && roomsRef.current.getBoundingClientRect().width > 800) {
      setDropdownRooms(true);
    }
  }, [roomsRef, home]);

  return (
    <div className="header">
      <div className="left">
        <div className="logo">
          <Link to={`/${site}`}>
            <img src={info?.logo_url ?? ''} alt={info?.title} />
          </Link>
        </div>
        {house && home && !summary && (
          <Link to={`/${site}`} className="back-btn">
            <span>&larr;</span>
            {house.title}
          </Link>
        )}

        {house && home && summary && (
          <Link to={`/${site}/house/${houseId}/${homeId}/${orderId}`} className="back-btn">
            <span>&larr;</span>
            {house.title}
          </Link>
        )}
      </div>

    </div>
  );
}

export default Header;
