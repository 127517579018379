import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HouseContext, OrderContext } from "./House";
import "./Summary.css";
import SavePDFIcon from "./savepdf.svg";
import SendMailIcon from "./sendemail.svg";
import SendSalesIcon from "./sendsales.svg";
import EmailSentIcon from "./emailsent.svg";
import { ModalContext } from "../Container/Container";
import axios from "axios";

function SummarySection({ section }) {
  if (!section.product) {
    return null;
  }

  const price = isNaN(parseFloat(section.product?.hind)) ? 0 : parseFloat(section.product?.hind);

  return (
    <div className="summary-room-section">
      <div className="summary-room-section-heading">
        <h3>{section.name}</h3>
        <div>+ {price} €</div>
      </div>

      <div className="summary-section-product">
        <div className="summary-section-product-image">
          {section.product.thumbnail && <img src={section.product.thumbnail} alt={section.product.title} />}
        </div>

        <div className="summary-section-product-body">
          <div className="summary-section-product-body-field">{section.product.title}</div>

          <ul>
            {section.product.info &&
              section.product?.show_info === true &&
              section.product.info.map((info, index) => (
                <li key={index}>
                  {info.show_key ? (
                    <>
                      {info.key}: {info.value}
                    </>
                  ) : (
                    info.value
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function SummaryRoom(room) {
  return (
    <div className="summary-room">
      <h2>{room.room.name}</h2>

      <div className="summary-room-sections">
        {room.room.sections?.map((section, index) => (
          <SummarySection key={index} section={section} />
        ))}
      </div>
    </div>
  );
}

function SendEmailModal() {
  const { order, setOrder } = useContext(OrderContext);
  const [terms, setTerms] = useState(false);
  const [noTerms, setNoTerms] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (info) {
      return;
    }

    axios
      .get(`${window.API_URL}/contact-info`)
      .then(({ data }) => {
        setInfo(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [info, setInfo]);

  const send = () => {
    if (!terms) {
      setNoTerms(true);

      return;
    }

    setNoTerms(false);

    axios
      .post(`${window.API_URL}/send-email`, {
        email,
        url: window.location.href,
      })
      .then((response) => {
        setOrder({
          ...order,
          email,
        });

        setSent(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const emailInvalid = (email) => {
    return email.length > 0 && !email.match(/[@]/g);
  };

  if (!info) {
    return null;
  }

  if (sent) {
    return (
      <div className="send-email-modal summary-modal email-sent">
        <img src={EmailSentIcon} alt="E-post saadetud" />
        <h3>Kokkuvõte saadetud e-postile</h3>
      </div>
    );
  }

  return (
    <div className="send-email-modal summary-modal sales-modal">
      <p> Salvesta oma senised valikud ja saada e-postile</p>
      <input
        type="text"
        name="email"
        className="input"
        placeholder="E-post"
        onInput={(e) => setEmail(e.target.value)}
      />
      {emailInvalid(email) && <p className="error">E-posti formaat ei ole korrektne!</p>}

      <label onClick={() => setTerms(!terms)}>
        <div className={terms ? "checkbox checked" : "checkbox"} />
        Nõustun tingimustega
      </label>
      {noTerms && <p className="error">Palun nõustu tingimustega</p>}

      <button type="button" onClick={() => send()}>
        Saada
      </button>
    </div>
  );
}

function SendSalesModal() {
  const { order, setOrder } = useContext(OrderContext);
  const [terms, setTerms] = useState(false);
  const [noTerms, setNoTerms] = useState(false);
  const [info, setInfo] = useState(null);
  const [sent, setSent] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("");

  useEffect(() => {
    if (info) {
      return;
    }

    axios
      .get(`${window.API_URL}/contact-info`)
      .then(({ data }) => {
        setInfo(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [info, setInfo]);

  const send = () => {
    if (!terms) {
      setNoTerms(true);

      return;
    }

    setNoTerms(false);

    axios
      .post(`${window.API_URL}/send-email`, {
        email: info.person_email,
        from: {
          email: userEmail,
          name: userName,
          number: userNumber,
        },
        url: window.location.href,
      })
      .then((response) => {
        setSent(true);

        setOrder({
          ...order,
          email: userEmail,
          name: userName,
          number: userNumber,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const emailInvalid = (email) => {
    return email.length > 0 && !email.match(/[@]/g);
  };

  if (!info) {
    return null;
  }

  if (sent) {
    return (
      <div className="send-email-modal summary-modal email-sent">
        <img src={EmailSentIcon} alt="E-post saadetud" />
        <h3>Kokkuvõte saadetud müügiesindajale</h3>
      </div>
    );
  }

  return (
    <div className="send-email-modal summary-modal sales-modal">
      <p>{info.sales_rep_description}</p>
      <div className="person">
        <div className="person-image">
          <img src={info.person_image} alt={info.person_name} />
        </div>

        <div className="person-body">
          <div className="person-name">{info.person_name}</div>
          <div className="person-phone">{info.person_number}</div>
          <div className="person-email">{info.person_email}</div>
        </div>
      </div>

      <input
        type="text"
        className="input"
        placeholder="Sinu nimi"
        onInput={(e) => setUserName(e.target.value)}
      />
      <input
        type="email"
        className="input"
        placeholder="Sinu e-post"
        onInput={(e) => setUserEmail(e.target.value)}
      />
      {emailInvalid(userEmail) && <p className="error">E-posti formaat ei ole korrektne!</p>}

      <input
        type="text"
        className="input"
        placeholder="Sinu telefoninumber"
        onInput={(e) => setUserNumber(e.target.value)}
      />

      <label onClick={() => setTerms(!terms)}>
        <div className={terms ? "checkbox checked" : "checkbox"} />
        Nõustun tingimustega
      </label>
      {noTerms && <p className="error">Palun nõustu tingimustega</p>}

      <button type="button" onClick={() => send()}>
        Saada
      </button>
    </div>
  );
}

function Summary() {
  const { house } = useContext(HouseContext);
  const { order, setOrder } = useContext(OrderContext);
  const { setModal } = useContext(ModalContext);
  const { site, orderId } = useParams();

  const totalPrice = () => {
    let total = 0;

    order?.rooms?.forEach((room) => {
      const products = room?.sections?.flatMap((section) => section?.product ?? []);
      const packets = products?.flatMap((product) => product?.pakett ?? []);
      const basePrice = packets.length > 0 && packets[0]?.price ? packets[0].price : 0;

      room.sections?.forEach((section) => {
        if (section.product) {
          const price = section.product.hind;
          if (/^\d+$/.test(price)) {
            total += parseFloat(price);
          }
        }
      });

      total += basePrice;
    });

    return total;
  };

  const downloadPDF = (_) => {
    window.location.href = `https://${window.location.hostname}/${site}/wp-json/reterra/pdf/${orderId}`;
  };

  const sendEmail = (_) => {
    setModal({
      title: "Saada e-postile",
      narrow: true,
      body: (
        <OrderContext.Provider value={{ order, setOrder }}>
          <SendEmailModal />
        </OrderContext.Provider>
      ),
    });
  };

  const sendSales = (_) => {
    setModal({
      title: "Saada müügiesindajale",
      narrow: true,
      body: (
        <OrderContext.Provider value={{ order, setOrder }}>
          <SendSalesModal />
        </OrderContext.Provider>
      ),
    });
  };

  return (
    <div className="summary">
      <div className="summary-content">
        {order?.rooms?.map((room, index) => (
          <SummaryRoom key={index} room={room} />
        ))}
      </div>

      <div className="summary-side">
        <h2>Kokkuvõte</h2>

        <div className="summary-side-total">
          <h3>Summa</h3>
          <div className="summary-side-total-price">{totalPrice()} €</div>
        </div>

        {house?.kokkuvotte_tekst.length > 0 && (
          <div className={"summary-side-text"}>{house.kokkuvotte_tekst}</div>
        )}

        <div>
          <button type="button" onClick={downloadPDF}>
            <img src={SavePDFIcon} alt="Salvesta PDF" />
            Salvesta PDF
          </button>
        </div>

        <div>
          <button type="button" onClick={sendEmail}>
            <img src={SendMailIcon} alt="Saada e-postile" />
            Saada e-postile
          </button>
        </div>

        <div>
          <button type="button" onClick={sendSales}>
            <img src={SendSalesIcon} alt="Saada müügiesindajale" />
            Saada müügiesindajale
          </button>
        </div>
      </div>
    </div>
  );
}

export default Summary;
