import House from "../components/House/House";
import ScrollToTop from "../ScrollToTop";
import Container from "../components/Container/Container";

const route = {
  path: "/:site/house/:houseId/:homeId/:orderId?/:summary?",
  element: (
    <ScrollToTop>
      <Container><House /></Container>
    </ScrollToTop>
  ),
};

export default route;
