import Orders from "../components/Orders/Orders";
import ScrollToTop from "../ScrollToTop";
import Container from "../components/Container/Container";

const route = {
  path: "/:site/orders",
  element: (
    <ScrollToTop>
      <Container>
        <Orders />
      </Container>
    </ScrollToTop>
  ),
};

export default route;
