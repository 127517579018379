import Houses from "../components/Houses/Houses";
import ScrollToTop from "../ScrollToTop";
import Container from "../components/Container/Container";

const route = {
  path: "/:site",
  element: (
    <ScrollToTop>
      <Container><Houses /></Container>
    </ScrollToTop>
  ),
};

export default route;
