import { useCallback, useContext, useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { HomeContext, HouseContext, OrderContext, RoomContext } from "../House/House";
import "./Home.css";
import { useNavigate, useParams } from "react-router-dom";
import Info from "../../icons/Info.svg";
import InfoIcon from "./InfoIcon.svg";
import CloseIcon from "./CloseIcon.svg";
import { ModalContext } from "../Container/Container";
import SectionModal from "./SectionModal";
import detailedPlanningIcon from "./detail_planning.svg";
import { v4 as uuidv4 } from "uuid";

function Product({ thumbnail, title, onClick, selected }) {
	return (
		<button onClick={onClick} className={selected ? "selected product" : "product"}>
			{thumbnail ? <img src={thumbnail} alt={title} /> : <div className="image-placeholder"></div>}

			<h3>{title}</h3>
		</button>
	);
}

function ProductSection({ name, description, products, packet, packetPrice, optional }) {
	const { order, setOrder } = useContext(OrderContext);
	const { room } = useContext(RoomContext);
	const { setModal } = useContext(ModalContext);
	const { orderId } = useParams();
	let filteredProducts = [];

	if (Array.isArray(products)) {
		filteredProducts = products.filter((p) => p.pakett?.name === packet);
	} else {
		const arr = Object.keys(products).map((key) => {
			return products[key];
		});
		filteredProducts = arr.filter((p) => p.pakett?.name === packet);
	}

	// Find selected product in this section
	const selectedProduct = useCallback(() => {
		const o_room = order?.rooms?.find((x) => x.name === room.room_name);

		if (!o_room) {
			return null;
		}

		const section = o_room.sections?.find((x) => x.name === name);

		if (!section) {
			return null;
		}

		return section.product;
	}, [name, order, room]);

	// Select a product
	const selectProductCb = (product) => {
		if (!room) {
			return;
		}

		// Does the product already exist, and section is optional, in which case remove the product
		if (optional && selectedProduct()?.product === product.product) {
			setOrder({
				...order,
				rooms: order?.rooms.map((x) => {
					if (x.name === room.room_name) {
						return {
							...x,
							sections: x.sections.filter((y) => y.name !== name),
						};
					}

					return x;
				}),
			});

			return;
		}

		// Does the room exist in the order?
		const o_room = order?.rooms?.find((x) => x.name === room.room_name);

		// If not, create it
		if (!o_room) {
			const newRoom = {
				name: room.room_name,
				sections: [
					{
						name,
						product,
					},
				],
			};

			setOrder({ ...order, rooms: [...(order?.rooms ?? []), newRoom] });
			return;
		}

		// If yes, does the section exist in the room?
		const section = o_room.sections?.find((x) => x.name === name);

		// If the section does not exist, create it
		if (!section) {
			setOrder({
				...order,
				rooms: order?.rooms.map((x) => {
					if (x.name === room.room_name) {
						return {
							...x,
							sections: [
								...x.sections,
								{
									name,
									product,
								},
							],
						};
					}

					return x;
				}),
			});

			return;
		}

		// If the section exists, update it
		setOrder({
			...order,
			rooms: order?.rooms.map((x) => {
				if (x.name === room.room_name) {
					return {
						...x,
						sections: x.sections.map((y) => {
							if (y.name === name) {
								return {
									...y,
									product,
								};
							}

							return y;
						}),
					};
				}

				return x;
			}),
		});
	};

	const selectProduct = useCallback(selectProductCb, [name, order, setOrder, room, optional, selectedProduct]);

	const showModal = (_) => {
		setModal({
			title: name,
			body: <SectionModal products={filteredProducts} />,
		});
	};

	const selectedProductIsDefaultProduct = () => {
		if (optional) {
			return false;
		}

		return selectedProduct()?.product === filteredProducts[0].product;
	};

	const price = () => {
		const val = parseFloat(selectedProduct()?.hind);

		if (isNaN(val) || selectedProductIsDefaultProduct()) {
			return 0;
		}

		return val;
	};

	useEffect(() => {
		if (!selectedProduct() && products.length && !orderId) {
			selectProduct(products[0]);
		}
	}, [order, orderId, products, selectProduct, selectedProduct]);

	if (!filteredProducts.length) {
		return null;
	}

	return (
		<>
			<div className="section">
				<div className="section-heading">
					<div>
						<h3>{name}</h3>
						<button type="button" onClick={showModal}>
							<span>
								<img src={Info} alt="" />
							</span>
							Lisainfo
						</button>
					</div>

					<div>+ {price()}&euro;</div>
				</div>

				{description.length > 0 && <div className="section-description">{description}</div>}

				<div className="products">
					{filteredProducts &&
						filteredProducts.map((product, index) => {
							return (
								<Product
									key={index}
									selected={selectedProduct()?.product === product.product}
									{...product}
									onClick={() => selectProduct(product)}
								/>
							);
						})}
				</div>
			</div>

			<hr />
		</>
	);
}

function ProductSections({ packet, packetPrice }) {
	const { room } = useContext(RoomContext);

	return (
		<div className="sections">
			{room.sections.length > 0 &&
				room.sections.map((section, index) => (
					<ProductSection key={index} packet={packet} packetPrice={packetPrice} {...section} />
				))}
		</div>
	);
}

function TotalPrice({ packetPrice }) {
	const { order } = useContext(OrderContext);
	const { room } = useContext(RoomContext);

	const totalAmount = () => {
		const o_room = order?.rooms?.find((x) => x.name === room.room_name);

		if (!o_room) {
			return 0;
		}

		let totalPrice = packetPrice ?? 0;

		for (const section of o_room.sections) {
			if (!section.product) {
				continue;
			}
			const price = parseFloat(section.product?.hind ?? 0);
			const sectionProducts = room.sections.find((s) => s.name === section.name).products;
			const isDefaultProduct =
				sectionProducts.length > 0 && sectionProducts[0].product === section.product.product;

			if (isNaN(price)) {
				continue;
			}

			if (isDefaultProduct) {
				continue;
			}

			totalPrice += parseFloat(section.product?.hind ?? 0);
		}

		return totalPrice;
	};

	return (
		<div className="total-price">
			<div>Kogusumma</div>
			<div>{totalAmount()}&euro;</div>
		</div>
	);
}

function Pagination() {
	const { home } = useContext(HomeContext);
	const { room, setRoom } = useContext(RoomContext);
	const name = room.room_name;
	const firstRoom = home?.rooms[0].room_name === name;
	const lastRoom = home?.rooms[home.rooms.length - 1].room_name === name;
	const navigate = useNavigate();
	const { site, houseId, homeId, orderId } = useParams();

	const goToPrev = () => {
		const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
		const prevIndex = currentIndex - 1;
		const prevRoom = home?.rooms[prevIndex];

		setRoom(prevRoom);
	};

	const getPrevRoom = () => {
		const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
		const prevIndex = currentIndex - 1;

		return home?.rooms[prevIndex];
	};

	const goToNext = () => {
		const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
		const nextIndex = currentIndex + 1;
		const nextRoom = home.rooms[nextIndex];

		setRoom(nextRoom);
	};

	const getNextRoom = () => {
		const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
		const nextIndex = currentIndex + 1;

		return home?.rooms[nextIndex];
	};

	const viewSummary = (_) => {
		navigate(`/${site}/house/${houseId}/${homeId}/${orderId}/summary`);
	};

	return (
		<div className="pagination">
			{!firstRoom && (
				<button className="back" onClick={goToPrev}>
					<span>{"<-"}</span>
					{getPrevRoom().room_name}
				</button>
			)}

			{!lastRoom && (
				<button className="forward" onClick={goToNext}>
					{getNextRoom().room_name}
					<span>{"->"}</span>
				</button>
			)}

			{lastRoom && (
				<button className="forward" onClick={viewSummary}>
					Kokkuvõte
					<span>{"->"}</span>
				</button>
			)}
		</div>
	);
}

function Sidebar() {
	const { house } = useContext(HouseContext);
	const { home } = useContext(HomeContext);
	const { room } = useContext(RoomContext);
	const { order, setOrder } = useContext(OrderContext);
	const [packet, setPacket] = useState(null);
	const navigate = useNavigate();
	const { site } = useParams();

	const packetPrice = useCallback(
		(name) => {
			if (!room || !room.sections) {
				return 0;
			}

			const products = room?.sections?.flatMap((section) => section.products) ?? [];
			const foundPacket = products?.flatMap((product) => product.pakett).find((p) => p?.name === name) ?? [];

			return foundPacket?.price ?? 0;
		},
		[room],
	);

	const packets = useCallback(() => {
		if (!room || !room.sections) {
			return [];
		}

		const products = room.sections?.flatMap((section) => section.products);
		const _packets = [];

		for (const p of products) {
			if (p.pakett && !_packets.includes(p.pakett.name)) {
				_packets.push(p.pakett.name);
			}
		}

		return _packets.map((p) => {
			const price = packetPrice(p);

			return {
				value: p,
				label: parseFloat(price) === 0 ? p : `${p} - ${packetPrice(p)}€`,
			};
		});
	}, [room, packetPrice]);

	useEffect(() => {
		if (packets().length && !packet) {
			setPacket(packets()[0].value);
		}
	}, [packets, packet, setPacket]);

	if (!home) {
		return null;
	}

	const homeOptions = () => {
		return house?.homes.map((home) => {
			return {
				value: home.id,
				label: `Korter ${home.number} - ${home?.room_count ?? 1} tuba - ${home.size} m²`,
			};
		});
	};
	const changeHome = (value) => {
		navigate(`/${site}/house/${house.id}/${value}`);
	};

	const defaultProductOfSectionInPacket = (section, packet) => {
		const productsInSection = room.sections?.find((s) => s.name === section && !s.optional)?.products;
		const productsInPacket = productsInSection?.filter((p) => p.pakett.name === packet);

		return productsInPacket?.length > 0 ? productsInPacket[0] : null;
	};

	const changePacket = (value) => {
		setPacket(value);
		setOrder({
			...order,
			rooms: order.rooms?.map((room) => {
				return {
					...room,
					sections: room.sections?.map((section) => {
						return {
							...section,
							product: defaultProductOfSectionInPacket(section.name, value),
						};
					}),
				};
			}),
		});
	};

	return (
		<div className="sidebar">
			<Dropdown options={homeOptions()} selectedValue={home.id} onChange={changeHome} />
			<Dropdown options={packets()} selectedValue={packet} onChange={changePacket} />
			<hr />
			<ProductSections packetPrice={packetPrice(packet)} packet={packet} />
			<TotalPrice packetPrice={packetPrice(packet)} />
			<hr />
			<Pagination />
		</div>
	);
}

function Planning({ home, signs, setSigns }) {
	if (!home || !home.planning.image) {
		return null;
	}

	const legends = home?.planning?.legend ?? home.planning.legend.filter(l => l.name !== '' && l.color !== '') ?? [];

	return (
		<div className="planning">
			<div className={legends.length > 0 ? 'planning-content' : 'planning-content no-signs'}>
				<div className="planning-legend">
					<div>
						{home.planning?.legend &&
							home.planning?.legend?.map((item, index) => (
								<div key={index}>
									<div className="color" style={{ backgroundColor: item.color }} />
									<div className="label">{item.name}</div>
								</div>
							))}
					</div>
				</div>

				<div className="planning-image">
					<img src={home.planning?.image} alt="" />
				</div>
			</div>

			{signs === true && (
				<button type="button" onClick={() => setSigns(!signs)}>
					<img src={InfoIcon} alt="Tingmärgid" />
					Tingmärgid
				</button>)}
		</div>
	);
}

function Signs({ sections, onClose }) {
	const [activeSection, setActiveSection] = useState(null);

	useEffect(() => {
		if (sections.length) {
			setActiveSection(sections[0].name);
		}
	}, [sections, setActiveSection]);

	const items = () => {
		return sections.find((x) => x.name === activeSection)?.signs;
	};

	if (!items()) {
		return null;
	}

	return (
		<div className="planning-signs">
			<div className="planning-signs-heading">
				<button type="button" onClick={() => onClose()}>
					<img src={CloseIcon} alt="" />
					Sulge tingmärgid
				</button>

				<div className="planning-signs-sections">
					{sections?.map((section, index) => (
						<button
							key={index}
							onClick={() => setActiveSection(section.name)}
							type="button"
							className={activeSection === section.name ? "selected" : ""}
						>
							{section.name}
						</button>
					))}
				</div>
			</div>

			<div className="planning-signs-items">
				{items().map((x, i) => (
					<div key={i} className="planning-signs-item">
						<img src={x.image} alt="" />
						<h3>{x.name}</h3>
					</div>
				))}
			</div>
		</div>
	);
}

function Content() {
	const { home } = useContext(HomeContext);
	const { order } = useContext(OrderContext);
	const { room, setRoom } = useContext(RoomContext);
	const { setModal } = useContext(ModalContext);
	const { site, houseId, homeId, orderId, summary } = useParams();
	const navigate = useNavigate();
	const room_order = order?.rooms?.find((r) => r.name === room.room_name);

	const handleSigns = (v) => {
		setModal({
			title: home.title,
			sidebar: v ? <Signs sections={home.planning.signs} onClose={() => handleSigns(false)} /> : false,
			body: <Planning house={home} signs={v} setSigns={handleSigns} />,
		});
	};

	const viewPlanning = (_) => {
		setModal({
			title: home.title,
			sidebar: false,
			body: <Planning home={home} signs={false} setSigns={handleSigns} />,
		});
	};

	if (!room_order) {
		return null;
	}

	const images = room_order.sections
		.filter((section) => section.product)
		.map((section) => {
			return section.product.image;
		})
		.filter((image) => {
			return typeof image === "string";
		});

	const handleRoomChange = (room) => {
		navigate(`/${site}/house/${houseId}/${homeId}/${orderId}`);
		setRoom(room);
	};

	const viewSummary = (_) => {
		navigate(`/${site}/house/${houseId}/${homeId}/${orderId}/summary`);
	};

	return (
		<div className="content-container">
			<div className="content">
				{images.map((image, index) => (
					<img key={uuidv4()} src={image} alt="" style={{zIndex: index + 1}}/>
				))}

				{images.length !== 0 && (
					<button type="button" onClick={viewPlanning}>
						<img src={detailedPlanningIcon} alt="Planeering"/>
						Vaata planeeringut
					</button>
				)}

				{home?.disclaimer?.length > 0 && (
					<div className={"disclaimer"}>{home.disclaimer}</div>
				)}
			</div>

			<div className={"room-selector"}>
				{home.rooms.map((x, index) => (
					<button
						className={x.room_name === room.room_name && !summary ? "active" : ""}
						onClick={() => handleRoomChange(x)}
						key={index}
					>
						{x.room_name}
					</button>
				))}

				<button className={summary ? "active" : ""} onClick={viewSummary}>
					Kokkuvõte
				</button>
			</div>
		</div>
	);
}

function Home() {
	return (
		<div className="home">
			<Sidebar/>
			<Content/>
		</div>
	);
}

export default Home;
