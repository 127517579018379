import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";

const name = window.location.pathname.split("/")[1].replaceAll("/", "");
const router = createBrowserRouter(routes);

console.log("router", router);
console.log("window.location.pathname", window.location.pathname);

const root = ReactDOM.createRoot(document.getElementById("root"));
const host = window.location.host;

window.API_URL = `https://${host}/${name}/wp-json/reterra`;
//window.API_URL = `https://konfiguraator.rktv.ee/demo/wp-json/reterra`;

root.render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router} />
    </App>
  </React.StrictMode>,
);
