import Header from "../Header/Header";
import "./Houses.css";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import {InfoContext, ModalContext} from "../Container/Container";
import {useNavigate, useParams} from "react-router-dom";

function Sidebar() {
	const { info } = useContext(InfoContext);

	return (
		<div className="sidebar">
			<h1>{info?.intro_title ?? 'Konfiguraatori pealkiri'}</h1>
			<div dangerouslySetInnerHTML={{__html: info?.intro_text ?? 'Konfiguraatori kirjeldus.'}} />
		</div>
	);
}

function HouseHomes({ house, homes }) {
	const { setModal } = useContext(ModalContext)
	const { site } = useParams();
	const navigate = useNavigate();

	const goToHome = (home) => {
		setModal(null);
		navigate(`/${site}/house/${house.id}/${home.id}`);
	}

	const fixedHomes = homes.map(home => {
		return {
			...home,
			type: home.type ?? "Korter"
		};
	});

	const groupBy = (array, key) => {
		return array.reduce((result, currentItem) => {
			(result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
			return result;
		}, {});
	};

	// group homes by type
	const items = groupBy(fixedHomes, "type");

	return (
		<div className="house-homes">
			{Object.entries(items).map(([k, v], i) => (
				<div key={i}>
					{i > 0 && <div style={{ marginTop: '50px' }} />}
					<h3>Vali {k.toLowerCase()}</h3>

					<table>
						<thead>
							<tr>
								<th>{k}i number</th>
								<th>Tubade arv</th>
								<th>Suurus</th>
								<th>Aadress</th>
							</tr>
						</thead>
						<tbody>
							{v.map((home, index) => (
								<tr key={index} onClick={() => goToHome(home)}>
									<td>{k} {home.number}</td>
									<td>{home.room_count}</td>
									<td>{home.size} m²</td>
									<td>{home.address} <span>&rarr;</span></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			))}
		</div>
	);
}

function Items() {
	const [projects, setProjects] = useState(null);
	const { setModal } = useContext(ModalContext)

	useEffect(() => {
		if (projects === null) {
			axios
				.get(`${window.API_URL}/projects`)
				.then(({ data }) => {
					setProjects(data);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [projects, setProjects]);

	const openProject = (project) => {
		// Has only 1 house?
		if (project.houses.length === 1) {
			openHouse(project.houses[0]);
		} else {
			setModal({
				title: project.title,
				narrow: true,
				body: (
					<div className="project-houses">
						<h3>Vali maja</h3>

						{project.houses.map((house, index) => (
							<button key={index} onClick={() => openHouse(house)}>{house.title}</button>
						))}
					</div>
				)
			});
		}
	}

	const openHouse = (house) => {
		setModal({
			title: house.title,
			body: <HouseHomes house={house} homes={house.homes} />
		});
	}

	if (!projects) {
		return null;
	}

	return (
		<div className="items">
			{projects.map((project, index) => (
				<div key={index} className="item">
					<div className="house-item" onClick={() => openProject(project)}>
						<h2>{project.title}</h2>

						{project.image.length > 0 ? (
							<img src={project.image} alt={project.title} />
						) : (
							<div className="img-placeholder" />
						)}
					</div>
				</div>
			))}
		</div>
	);
}

function Houses() {
	return (
		<div className="houses">
			<div className="container">
				<Header />
				<div className={"cols"}>
					<Items />
					<Sidebar />
				</div>
			</div>
		</div>
	);
}

export default Houses;
